import React, { Fragment, ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import { Language } from '../../global/locale/locale-handler/locale-handler-enum';
import { Route } from '../../global/route-enum';
import Footer from '../footer/footer';
import Header from '../header/header';
import SEO from '../seo/seo';
import { ILayoutProperties } from './layout-interface';

export default class Layout extends BaseComponent<ILayoutProperties> {
  protected declareTranslateCollection(): string {
    return 'layout';
  }

  public render(): ReactNode {
    const { subtitle, title, description, children } = this.props;

    return (
      <Fragment>
        <SEO
          pageTitle={`${this.translate('siteName')} - ${title}`}
          description={description}
          keywords={['state', 'democracy']}
          language={Language.EN_US}
        />
        <Header
          menuItems={[
            { title: this.translate('overall'), path: Route.HOME },
            {
              title: this.translate('participation'),
              path: Route.PARTICIPATION,
            },
            { title: this.translate('competition'), path: Route.COMPETITION },
            {
              title: this.translate('representation'),
              path: Route.REPRESENTATION,
            },
            { title: this.translate('polarization'), path: Route.POLARIZATION },
          ]}
          subtitle={subtitle}
        />
        {children}
        <Footer
          menuItems={[
            { title: this.translate('about'), path: Route.ABOUT },
            { title: this.translate('media'), path: Route.MEDIA },
            { title: this.translate('takeAction'), path: Route.TAKE_ACTION },
            {
              title: this.translate('readTheReport'),
              path: Route.READ_THE_REPORT,
            },

            { title: this.translate('methodology'), path: Route.METHODOLOGY },
            {
              title: this.translate('data'),
              path: Route.DATA,
              isExternal: true,
            },

            {
              title: this.translate('stateOfReform'),
              path: Route.STATE_OF_REFORM,
            },
          ]}
        />
      </Fragment>
    );
  }
}
