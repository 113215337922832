import React, { ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import SolidLink from '../solid-link/solid-link';
import { SolidLinkColorSchemas } from '../solid-link/solid-link-enum';
import SvgIcon from '../svg-icon/svg-icon';
import { SvgIconPathCommand } from '../svg-icon/svg-icon-enum';
import {
  IWhatToDoHomeItem,
  IWhatToDoHomeProperties,
} from './what-to-do-home-interface';
import './what-to-do-home.scss';

export default class WhatToDoHome extends BaseComponent<IWhatToDoHomeProperties> {
  protected declareTranslateCollection(): string | undefined {
    return 'whatToDoHome';
  }

  public render(): ReactNode {
    const { items } = this.props;

    return (
      <div className="c-what-todo">
        <div className="c-section">
          <h2 className="c-section__title">{this.translate('whatToDo')}</h2>
        </div>
        <ul className="row c-list">
          {items.map((item: IWhatToDoHomeItem, index: number) => {
            return (
              <li className="col-lg-4 c-list__items" key={index}>
                <span className="c-list__num">{`${index + 1}.`}</span>
                <h5 className="c-list__title">{item.title}</h5>
                <p className="c-list__text">{item.description}</p>
                {item.moreInfoLink ? (
                  <SolidLink
                    colorSchema={SolidLinkColorSchemas.TRANSPARENT}
                    to={item.moreInfoLink}
                  >
                    {item.moreInfoTitle}
                    <SvgIcon
                      width="18"
                      viewBox="0 0 18 13"
                      paths={[
                        {
                          command: SvgIconPathCommand.M11_ARROW_RIGHT,
                          fill: '#000',
                        },
                      ]}
                    />
                  </SolidLink>
                ) : (
                  <></>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
