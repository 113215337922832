import { Link } from 'gatsby';
import React, { Fragment, ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import { Route } from '../../global/route-enum';
import BreadCrumb from '../bread-crumb/bread-crumb';
import ContainerBox from '../container-box/container-box';
import Menu from '../menu/menu';
import { IMenuItem } from '../menu/menu-interface';
import SvgIcon from '../svg-icon/svg-icon';
import { SvgIconPathCommand } from '../svg-icon/svg-icon-enum';
import { IHeaderProperties, IHeaderState } from './header-interface';
import './header.scss';

export default class Header extends BaseComponent<
  IHeaderProperties,
  IHeaderState
> {
  private pathname: string;

  public constructor(properties: IHeaderProperties) {
    super(properties);

    this.pathname = '';
    this.state = {
      isMobileNavOpen: false,
    };
  }

  public componentDidMount(): void {
    this.pathname = window.location.pathname;
  }
  private onHamburgerClick(): void {
    this.setState({
      isMobileNavOpen: !this.state.isMobileNavOpen,
    });
  }

  public render(): ReactNode {
    const { subtitle, menuItems, breadCrumbItems = [] } = this.props;
    const { isMobileNavOpen } = this.state;
    const mobileClassName: string = [
      's-nav js-nav',
      isMobileNavOpen ? 'is-open' : '',
    ].join(' ');

    return (
      <Fragment>
        <header className="o-section c-section--header">
          <div className="o-section__wrapper">
            <div className="c-header">
              <div className="c-header__col">
                <div className="c-logo">
                  <Link
                    to={Route.HOME}
                    className="c-link c-link--logo"
                    aria-label="State of Democracy"
                  >
                    <SvgIcon
                      width="208"
                      viewBox="0 0 208 44"
                      paths={[
                        {
                          command: SvgIconPathCommand.STATE_OF_DEMOCRACY,
                          fill: '#fff',
                        },
                        {
                          fillRule: 'evenodd',
                          clipRule: 'evenodd',
                          command: SvgIconPathCommand.LOGO_EAST,
                          fill: '#EDE6FA',
                        },
                        {
                          fillRule: 'evenodd',
                          clipRule: 'evenodd',
                          command: SvgIconPathCommand.LOGO_WEST,
                          fill: '#8F60E1',
                        },
                      ]}
                    />
                  </Link>
                </div>
              </div>
              <div className="c-header__col">
                <div className="c-hamburger__wrap">
                  <div
                    className="c-hamburger"
                    onClick={(): void => {
                      this.onHamburgerClick();
                    }}
                  >
                    <input
                      className="c-hamburger__checkbox js-hamburger"
                      type="checkbox"
                      aria-label="Menu"
                    />
                    <span className="c-hamburger__icon"></span>
                    <span className="c-hamburger__icon"></span>
                    <span className="c-hamburger__icon"></span>
                  </div>
                </div>
                <ul className={mobileClassName}>
                  <li>
                    <Link to={Route.ABOUT}>About</Link>
                  </li>
                  <li>
                    <Link to={Route.MEDIA}>Media</Link>
                  </li>
                  <li>
                    <Link to={Route.TAKE_ACTION}>Take Action </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </header>
        <ContainerBox className="c-section--tab">
          {breadCrumbItems.length > 0 && (
            <div>
              <BreadCrumb crumbItems={breadCrumbItems} />
            </div>
          )}

          <Menu
            items={menuItems}
            selectedItemIndex={0}
            isActive={(item: IMenuItem): boolean => {
              return this.checkMenuActivity(item);
            }}
          />
        </ContainerBox>
        {subtitle ? (
          <ContainerBox className="c-section--description">
            <div className="c-tab__subtitle">
              <SvgIcon
                width="20"
                viewBox="0 0 20 20"
                paths={[
                  { command: SvgIconPathCommand.SUBTITLE, fill: '#4B4950' },
                ]}
              />

              <span>{subtitle}</span>
            </div>
          </ContainerBox>
        ) : (
          ''
        )}
      </Fragment>
    );
  }

  private checkMenuActivity(item: IMenuItem): boolean {
    return item.path === this.pathname || `${item.path}/` === this.pathname;
  }
}
