import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React, { ReactNode } from 'react';
import fixUS from '../../assets/images/fixus-logo.png';
import BaseComponent from '../../base/base-component';
import { Route } from '../../global/route-enum';
import SvgIcon from '../svg-icon/svg-icon';
import { SvgIconPathCommand } from '../svg-icon/svg-icon-enum';
import { IFooterMenuItem, IFooterProperties } from './footer-interface';
import './footer.scss';

export default class Footer extends BaseComponent<IFooterProperties> {
  public render(): ReactNode {
    const { menuItems } = this.props;

    return (
      <footer className="o-section c-section--footer">
        <div className="o-section__wrapper">
          <div className="c-footer">
            <ul className="s-nav-footer">
              {menuItems.map(
                (menuItem: IFooterMenuItem, index: number): ReactNode => {
                  return (
                    <li key={index}>
                      {menuItem.isExternal ? (
                        <OutboundLink href={menuItem.path}>
                          {menuItem.title}
                        </OutboundLink>
                      ) : (
                        <Link to={menuItem.path}>{menuItem.title}</Link>
                      )}
                    </li>
                  );
                }
              )}
            </ul>
            <div className="row c-footer__info">
              <div className="col-lg-4 order-lg-first order-2 c-footer__details">
                <div className="c-footer__logo">
                  <Link to={Route.HOME}>
                    <SvgIcon
                      width="208"
                      viewBox="0 0 208 44"
                      paths={[
                        {
                          command: SvgIconPathCommand.STATE_OF_DEMOCRACY,
                          fill: '#fff',
                        },
                        {
                          fillRule: 'evenodd',
                          clipRule: 'evenodd',
                          command: SvgIconPathCommand.LOGO_EAST,
                          fill: '#EDE6FA',
                        },
                        {
                          fillRule: 'evenodd',
                          clipRule: 'evenodd',
                          command: SvgIconPathCommand.LOGO_WEST,
                          fill: '#8F60E1',
                        },
                      ]}
                    />
                  </Link>
                </div>
                <div className="c-footer__copyright">
                  <ul className="s-nav-secondary">
                    <li>
                      <Link to={Route.CONTACT}>Contact</Link>
                    </li>
                    <li>
                      <Link to={Route.PRIVACY_POLICY}>Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 offset-lg-2 c-footer-partners">
                <div className="c-footer__partners">
                  <div className="c-footer-partners__title">
                    Project Partners
                  </div>
                  <div className="c-partners">
                    <OutboundLink
                      href="https://fixusnow.org/"
                      aria-label="FixUS"
                      target='_blank'
                      rel='nofollow, noopener'
                    >
                      <img width="57" src={fixUS} alt="FixUs Logo" />
                    </OutboundLink>
                    <OutboundLink
                      href="https://freedomhouse.org/"
                      aria-label="Freedom House"
                      target='_blank'
                      rel='nofollow, noopener'
                    >
                      <SvgIcon
                        width="96"
                        viewBox="0 0 96 43"
                        paths={[
                          {
                            command: SvgIconPathCommand.FREEDOM_HOUSE,
                            fill: '#fff',
                            fillOpacity: '.5',
                          },
                        ]}
                      />
                    </OutboundLink>
                    <OutboundLink
                      href="https://represent.us/"
                      aria-label="RepresentUs"
                      target='_blank'
                      rel='nofollow, noopener'
                    >
                      <SvgIcon
                        width="146"
                        viewBox="0 0 146 35"
                        paths={[
                          {
                            command: SvgIconPathCommand.REPRESENT_US_1,
                            fill: '#fff',
                            fillOpacity: '.65',
                          },
                          {
                            command: SvgIconPathCommand.REPRESENT_US_2,
                            fill: '#fff',
                            fillOpacity: '.65',
                          },
                          {
                            command: SvgIconPathCommand.REPRESENT_US_3,
                            fill: '#fff',
                            fillOpacity: '.65',
                          },
                        ]}
                      />
                    </OutboundLink>

                    <OutboundLink
                      href="https://www.uniteamericainstitute.org/"
                      aria-label="Unite America Institute"
                      target='_blank'
                      rel='nofollow, noopener'
                    >
                      <SvgIcon
                        width="142"
                        viewBox="0 0 142 31"
                        paths={[
                          {
                            command: SvgIconPathCommand.INSTITUTE_1,
                            fill: '#fff',
                            fillOpacity: '.5',
                          },
                          {
                            command: SvgIconPathCommand.INSTITUTE_2,
                            fill: '#fff',
                            fillRule: 'evenodd',
                            clipRule: 'evenodd',
                            fillOpacity: '.65',
                          },
                        ]}
                      />
                    </OutboundLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
